<template>

  <el-table-column
    :label="columnConfig.label"
    :align="columnConfig.align"
  >
    <template slot="header">
      <div class="medium-fact-column">
        <div>{{columnConfig.label}}</div>
        <el-button
          :icon="showFullData ? 'fas fa-angle-double-left' : 'fas fa-angle-double-right'"
          type="text"
          @click="showFullData = !showFullData"
        ></el-button>
      </div>
    </template>


    <el-table-column
      v-for="period in 4"
      v-if="isToShowColumn(period)"
      :key="period"
      :label="'Q' + period.toString()"
      width="120"
    >
      <template v-slot="scope">
        <element-medium-fact-period
          :period="period"
          :scope="scope"
          :disabled="!isColumnEnabled(period) || !isFieldAvailableForEdit(scope.row, 'medium_fact')"
        ></element-medium-fact-period>
      </template>
    </el-table-column>
  </el-table-column>

</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import ElementMediumFactPeriod from "@/components/tableColumnTemplates/card/mediumFacts/ElementMediumFactPeriod";
export default {
  name: 'element-medium-facts-table-column',
  components: {ElementMediumFactPeriod, ClickToEdit},

  props: {
    // scope: {type: Object, required: true},
    // isFieldAvailableForEdit: {type: Function, required: true},
    columnConfig: {type: Object, required: true},
  },

  computed: {},
  data() {
    return {
      showFullData: false,

      periodsMap: {
        1: [1, 3],
        2: [4, 6],
        3: [7, 9],
        4: [10, 12],
      },
    }
  },
  methods: {
    isFieldAvailableForEdit(element, fieldName){
      return element.fields_permissions && element.fields_permissions[fieldName];
    },
    isToShowColumn(period){
      if( this.showFullData ){
        return true;
      }
      let currentMonth = (new Date()).getMonth() + 1;
      return this.periodsMap[period][0] <= currentMonth && currentMonth <= this.periodsMap[period][1];
    },
    isColumnEnabled(period){
      let currentMonth = (new Date()).getMonth() + 1;
      return  this.periodsMap[period][0] <= currentMonth
        && currentMonth <= this.periodsMap[period][1]+1;
    }
  }
}

</script>

<style>
.medium-fact-column {
  display: flex;
  justify-content: space-between;
}
</style>
